
import {computed, defineComponent, ref} from "vue";
import QuickAction from "../../components/base/action/QuickAction.vue";
import Entities from "@/components/base/common/Entities.vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {LoadFilterObjects} from "@/core/composite/composite";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import * as Yup from "yup";
import {Field} from "vee-validate";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import LookupService from "@/core/services/LookupService";
import Swal from "sweetalert2";

export default defineComponent({
  name: "List",
  components: {FormErrorMessage, Field, BaseForm, BaseModal, BaseSelect, KtDatatable, Entities, QuickAction},
  setup() {
    setCurrentPageBreadcrumbsWithParams('Lookups',
      [
        {link: false, router: '', text: 'Lookups'}
      ]
    )
    const submitting = ref(false)
    const state = ref({id: '', model: {type: '', name: '', code: ''}, title: 'Add Code'});
    const filterObject = ref({type: '', code: ''})
    const page = computed(() => store.state.LookupModule.page);
    const currentUser = computed(()=>store.getters.currentUser);
    const header = [
      {
        name: "Code",
        key: "code",
        sortable: true,
      },
      {
        name: "Type",
        key: "type",
        sortable: true,
      },
      {
        name: "Value",
        key: "value",
        sortable: true,
      },
      {
        name: "",
        key: "actions",
        sortable: true,
      },
    ]
    const validate = Yup.object().shape({
      name: Yup.string().required().max(255).label("Value"),
      code: Yup.string().required().max(10).label("Code"),
      type: Yup.string().required().max(10).label("Code"),
    });
    return {
      validate,
      submitting,
      state,
      filterObject,
      currentUser,
      header,
      page,
      ...LoadFilterObjects(Actions.LOAD_LOOKUPS, filterObject.value, [])
    }
  },
  methods: {
    onSubmit() {
      this.submitting = true;
      if (this.state.id) {
        LookupService.update(this.state.id, this.state.model).then(() => {
          this.paginationLoad()
          const modal = this.$refs.formLookupRef as typeof BaseModal
          modal.hideBaseModal();
        }).finally(() => {
          this.submitting = false;
        })
      } else {
        LookupService.create(this.state.model).then(() => {
          this.paginationLoad()
          const modal = this.$refs.formLookupRef as typeof BaseModal
          modal.hideBaseModal();
        }).finally(() => {
          this.submitting = false;
        })
      }
    },
    onAdd() {
      this.state.title = "Add Code"
      this.state.model = {type: 'DENY', code: '', name: ''}
      const modal = this.$refs.formLookupRef as typeof BaseModal
      modal.showBaseModal();
    },
    discardEvent() {
      this.filterObject.type = ''
      this.updateFilterObject(this.filterObject);
    },
    searchEvent() {
      this.updateFilterObject(this.filterObject)
    },
    onEdit(data) {
      this.state.title = "Edit Code"
      this.state.id = data.id;
      this.state.model.type = data.type
      this.state.model.code = data.code
      this.state.model.name = data.name
      const modal = this.$refs.formLookupRef as typeof BaseModal
      modal.showBaseModal();
    },
    onDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await LookupService.delete(id).then(() => {
            this.paginationLoad();
          })
        }
      })
    }
  }
})
